import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const sweetAlert = (title, icon) => {
  return MySwal.fire({
    title, //"Subscription Purchased Successfully "
    icon, // "success"
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });
};
