// ** React Imports
import { Fragment, useState, useRef } from "react";

// ** Reactstrap Imports
import { Modal, ModalBody, ModalHeader } from "reactstrap";

// ** Custom Components
import Wizard from "@components/wizard";

// ** Icons Imports
import { Book, Check, CreditCard, X } from "react-feather";

// ** Styles
import "@styles/react/pages/modal-create-app.scss";
import BillingAddress from "./BillingAddress";

import CreditCardComponent from "./Card";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmPayment,
  createPaymentMethod,
  createStripeCustomer,
} from "../../services/subscription.service";
// ** Toast
import ToastContent from "../../views/components/toast/toast";
import toast from "react-hot-toast";
import { getPaymentMethod } from "../../redux/userSlice/user.thunk";
import { getErrorMsg } from "../ErrorMessages/errorMessage";
import { getCurrentUser } from "../../redux/currentUserSlice/currentUser.thunk";
import { useNavigate } from "react-router-dom";
import { USER_ROUTES } from "../../constant/route";
import CPtoClipBoardModal from "../../views/components/modals/CPtoClipboard";
import UILoader from "@components/ui-loader";
import Loader from "../Loader/Loader";
import { sweetAlert } from "../SweetAlert/SweetAlert";

const PaymentWizard = ({
  show,
  setShow,
  module,
  setCalculatorModel,
  setConfSubscription,
  handlecreateProject,
}) => {
  // ** navigate
  const navigate = useNavigate();
  // ** Ref
  const ref = useRef(null);

  const dispatch = useDispatch();

  const elements = useElements();
  const stripe = useStripe();

  // ** Redux
  const subscriptionData = useSelector((state) => state.subscription);
  const currentUser = useSelector((state) => state.currentUser);

  // ** States
  const [stepper, setStepper] = useState(null);
  const [billing, setBilling] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [CopyModal, setCopyModal] = useState({ isOpen: false, data: "" });

  const handleNext = async (data) => {
    const activeStep = stepper._currentIndex;

    if (activeStep === 0) {
      setBilling(data);
      stepper.next();
    } else {
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
      const billingData = {
        name: data,
        address: {
          city: billing?.town,
          country: billing?.country?.value,
          line1: billing?.address1,
          line2: billing?.address2,
          postal_code: billing?.zip_code,
          state: billing?.province,
        },
      };
      try {
        const payload = await stripe.confirmCardSetup(
          subscriptionData?.subscription_details?.client_secret,
          {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: billingData,
            },
          }
        );
        if (payload?.error) {
          setIsLoading(false);
          const err = payload?.error?.message;
          toast((t) => (
            <ToastContent
              color="danger"
              t={t.id}
              message={err}
              icon={<X size={12} />}
            />
          ));
        } else {
          const value = {
            user_id: currentUser?.currentuserdata?.data.id,
            payment_method_id: payload.setupIntent?.payment_method,
            setup_intent_id: payload.setupIntent?.id,
          };

          createPaymentMethod(value)
            .then(() => {
              if (!module) {
                setIsLoading(false);
                setShow(false);
              }

              if (module === "orderpage") {
                handlecreateProject();
              }

              dispatch(getPaymentMethod(currentUser?.currentuserdata?.data.id));
              if (
                module === "subscription" ||
                module === "pricing_calculator"
              ) {
                const subscriptionID = {
                  subscription_id:
                    subscriptionData?.subscription_details?.planId,
                };
                // if()

                createStripeCustomer(
                  subscriptionID,
                  currentUser?.currentuserdata?.data?.user_subscription_id
                )
                  .then((res) => {
                    if (module === "pricing_calculator") {
                      setCalculatorModel(false);

                      sweetAlert(
                        "Subscription Purchased Successfully",
                        "success"
                      );
                    }

                    if (module === "subscription") {
                      setConfSubscription({ open: false, value: "" });
                    }

                    dispatch(getCurrentUser());
                    if (module) {
                      setIsLoading(false);
                      setShow(false);
                      navigate(USER_ROUTES.MY_PROJECTS);
                    }
                    toast((t) => (
                      <ToastContent
                        t={t.id}
                        message={res?.message}
                        icon={<Check size={12} />}
                      />
                    ));
                  })
                  .catch(async (err) => {
                    if (err.response?.data?.data?.is_stripe_payment_error) {
                      const res = await stripe.confirmCardPayment(
                        err.response?.data?.data.payment_intent_client_secret
                      );

                      if (!res.error) {
                        const payload = {
                          payment_intent_id: res.paymentIntent.id,
                          user_subscription_id:
                            err.response?.data?.data.user_subscription_id,
                        };

                        setIsLoading(true);
                        const response = await confirmPayment(payload);

                        if (module === "pricing_calculator") {
                          setCalculatorModel(false);

                          sweetAlert(
                            "Subscription Purchased Successfully",
                            "success"
                          );
                        }

                        if (module === "subscription") {
                          setConfSubscription({ open: false, value: "" });
                        }

                        dispatch(getCurrentUser());
                        if (module) {
                          setIsLoading(false);
                          setShow(false);
                          navigate(USER_ROUTES.MY_PROJECTS);
                        }
                        toast((t) => (
                          <ToastContent
                            t={t.id}
                            message={response?.message}
                            icon={<Check size={12} />}
                          />
                        ));
                      } else {
                        setIsLoading(false);
                        setShow(false);
                        toast((t) => (
                          <ToastContent
                            t={t.id}
                            message="We are unable to authenticate your payment method."
                            color="danger"
                            icon={<X size={12} />}
                          />
                        ));
                      }

                      // const win = window.open(
                      //   err.response?.data?.data?.redirect_to
                      //   // "_blank"
                      // );
                      // if (win === null || typeof win === "undefined") {
                      //   setCopyModal({
                      //     isOpen: true,
                      //     data: err.response?.data?.data,
                      //   });
                      //   if (module === "subscription") {
                      //     setConfSubscription({ open: false, value: "" });
                      //   }
                      //   setShow(false);
                      //   setIsLoading(false);

                      //   // Popup blocked, display message in UI
                      //   toast((t) => (
                      //     <ToastContent
                      //       t={t.id}
                      //       message="Popup blocked. Please enable popups and try again."
                      //       color="danger"
                      //       icon={<X size={12} />}
                      //     />
                      //   ));
                      // } else {
                      //   setShow(false);
                      //   setIsLoading(false);
                      //   navigate(USER_ROUTES.MY_PROJECTS);
                      // }
                    } else if (err?.response?.data?.data?.redirect_to) {
                      // ! payment wizard redirect_to
                      const win = window.open(
                        err.response?.data?.data?.redirect_to,
                        "_blank"
                      );

                      if (win === null || typeof win === "undefined") {
                        setCopyModal({
                          isOpen: true,
                          data: err.response?.data?.data,
                        });
                        // Popup blocked, display message in UI
                        toast((t) => (
                          <ToastContent
                            t={t.id}
                            message="Popup blocked. Please enable popups and try again."
                            color="danger"
                            icon={<X size={12} />}
                          />
                        ));
                      } else {
                        getErrorMsg(err);
                        setShow(false);
                        setIsLoading(false);
                      }
                    } else {
                      setShow(false);
                      setIsLoading(false);
                      getErrorMsg(err);
                    }
                  });
              }
            })
            .catch((err) => {
              setShow(false);
              setIsLoading(false);
              getErrorMsg(err);
              console.log(err);
            });
          // .finally(() => {
          //   setIsLoading(false);
          // });
        }
      } catch (err) {
        console.log("err>>>>>", err);
        getErrorMsg(err);
      }
    }
  };

  const steps = [
    {
      id: "details",
      title: "Billing Address",
      subtitle: "Enter Billing Address",
      icon: <Book className="font-medium-3" />,
      content: <BillingAddress stepper={stepper} handleNext={handleNext} />,
    },
    {
      id: "frameworks",
      title: "Card Details",
      subtitle: "Add Card",
      icon: <CreditCard className="font-medium-3" />,
      content: (
        <CreditCardComponent
          stepper={stepper}
          handleNext={handleNext}
          isLoading={isLoading}
          closeModel={setShow}
        />
      ),
    },
  ];

  return (
    <Fragment className="">
      <div>
        <Modal
          isOpen={show}
          toggle={() => setShow(!show)}
          className="modal-dialog-centered modal-lg modal-dialog-scrollable  "
          backdrop="static"
        >
          <UILoader blocking={isLoading} loader={<Loader />}>
            <ModalHeader
              className={`bg-transparent ${isLoading && "btn-loading"}`}
              toggle={() => setShow(!show)}
            ></ModalHeader>
            <ModalBody className="pb-3 px-sm-3 p-0">
              <Wizard
                ref={ref}
                steps={steps}
                // type="vertical"
                headerClassName="border-0"
                options={{ linear: true }}
                instance={(el) => setStepper(el)}
                contentClassName="shadow-none"
                className="bg-transparent create-app-wizard shadow-none"
                contentWrapperClassName="p-25"
              />
            </ModalBody>
          </UILoader>
        </Modal>
      </div>
      <CPtoClipBoardModal setCopyModal={setCopyModal} CopyModal={CopyModal} />
    </Fragment>
  );
};

export default PaymentWizard;
