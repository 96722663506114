export const STRIPE_TEST_KEY = `${process.env.REACT_APP_STRIPE_TEST_KEY}`;
export const PerPaginationPageData = 25;

export const ROLE = {
  SUPER_ADMIN: 2,
  ADMIN: 3,
  CUSTOMER_SUPPORT: 4,
  EDITOR: 5,
  USER: 6,
};

export const MODULE = {
  SUBSCRIPTION: "subscription",
};

export const MODEL_POPUPCONTENT = {
  SUBSCRIPTION_TERMS: 3,
  ORDER_TERMS: 2,
};

export const COWORKERS_DATA = [
  {
    id: ROLE?.ADMIN,
    label: "Admin",
  },
  {
    id: ROLE?.CUSTOMER_SUPPORT,
    label: "Customer Support",
  },
  {
    id: ROLE?.EDITOR,
    label: "Default Editor",
  },
];

// ! coin field is disable or don't view // when true don't show in portal
export const coinFieldIsDisable = true;
