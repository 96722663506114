import React, { memo, useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getPublisherKey } from "../../services/myProject.services";
import { setStripePublicKey } from "../../redux/currentUserSlice/currentUser";

const StripeElement = ({ children }) => {
  const dispatch = useDispatch();
  const [stripeKey, setStripeKey] = useState(null);
  const stripePromise = loadStripe(stripeKey);
  const token = localStorage.getItem("accessToken");
  const auth = useSelector((state) => state.auth);

  const handleApiCall = async () => {
    const {
      data: { stripe_public_key },
    } = await getPublisherKey();

    dispatch(setStripePublicKey(stripe_public_key));
    setStripeKey(stripe_public_key);
  };

  useEffect(() => {
    if (token) {
      handleApiCall();
    }
  }, [auth.userData]);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

StripeElement.propTypes = {
  children: PropType.node.isRequired,
};

export default memo(StripeElement);
