import moment from "moment";

export const getEventRange = (eventsPerYear) => {
  switch (eventsPerYear) {
    case "1":
      return "<10";
    case "2":
      return "10 to 20";
    case "3":
      return "20 to 30";
    case "4":
      return "30 to 40";
    case "5":
      return "40+";
    default:
      return "-";
  }
};

export function numberWithCommas(x) {
  return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function renderMessageSegments(message) {
  if (typeof message !== "string") {
    return null;
  }
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const segments = message.split(urlRegex);
  return segments.map((segment, index) => {
    if (urlRegex.test(segment)) {
      return (
        <a
          href={segment}
          target="_blank"
          key={index}
          style={{ wordWrap: "break-word" }}
        >
          {segment}
        </a>
      );
    } else {
      return <span key={index}>{segment}</span>;
    }
  });
}

export const dateFormat = (value) => {
  if (value) {
    const date = moment(value).format("MM/DD/YY");
    return date;
  }
};
