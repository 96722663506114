import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import LineSkelaton from "../LineSkelaton";
import classNames from "classnames";

const PlansSkeleton = () => {
  return (
    <>
      <div className="container loading-skeleton">
        <div className="row">
          {[...Array(3)].map((_, index) => (
            <>
              <Col xs={12} md={6} lg={6} xl={4} key={index}>
                <Card className={classNames("bordered shadow-none", {})}>
                  <CardBody>
                    <div className="annual-plan">
                      <div className="price-div">
                        <LineSkelaton />
                      </div>
                    </div>

                    <div className="annual-plan">
                      <div className="price-div">
                        <span className="price">
                          <LineSkelaton />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                  <div className="middle-points">
                    <CardBody>
                      <div className="annual-plan">
                        <div className="price-div">
                          <span className="price">
                            <LineSkelaton />
                          </span>
                        </div>
                      </div>
                      <div className="annual-plan">
                        <div className="price-div">
                          <LineSkelaton />
                        </div>
                      </div>

                      <div className="annual-plan">
                        <div className="price-div">
                          <LineSkelaton />
                        </div>
                      </div>
                    </CardBody>
                  </div>
                  <CardBody>
                    <div className="annual-plan">
                      <div className="price-div">
                        <LineSkelaton />
                      </div>
                    </div>
                    <div className="annual-plan">
                      <div className="price-div">
                        <LineSkelaton />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default PlansSkeleton;
