import React from "react";
import { Button } from "reactstrap";

const ErrorBoundaryFallback = ({
  // error,
  //  componentStack,
  resetError,
}) => {
  return (
    <React.Fragment>
      <div className="errorBoundary">
        <h1>Something Went Wrong</h1>
        {/* <h5>{error.toString()}</h5> */}
        {/* <div>{componentStack}</div> */}
        <Button color="primary" className="mt-50" onClick={resetError}>
          Refresh Page
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ErrorBoundaryFallback;
