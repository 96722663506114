import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#f5693b",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "14px",
      color: "#f5693b",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#f5693b",
      },
      "::placeholder": {
        color: "#7F7F7F",
      },
    },
    invalid: {
      iconColor: "#f5693b",
    },
  },
};

const StripeCard = ({ onChange }) => {
  return (
    <div className="cutom_StripeCard form-control ">
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
  );
};

export default StripeCard;
