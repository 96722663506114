import React, { useState } from "react";
import { Button, Col, FormFeedback, Input, Label, Row, Form } from "reactstrap";
import StripeCard from "../stripeComponent/StripeCard";
import ToastContent from "../../views/components/toast/toast";
import toast from "react-hot-toast";
import { X } from "react-feather";

const CreditCard = ({ handleNext, isLoading, closeModel }) => {
  const [cardName, setCardName] = useState("");
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);

  // const validateCardName = (input) => {
  //   // Regex pattern to disallow numbers and special characters
  //   const pattern = /^[A-Za-z ]*$/;

  //   return pattern.test(input);
  // };

  const validateCardName = (input) => {
    // Regex pattern to disallow numbers and special characters
    const pattern = /^[A-Za-z][A-Za-z ]*$/;

    return pattern.test(input);
  };

  // const handleChangeCardName = (e) => {
  //   const inputValue = e.target.value;

  //   if (validateCardName(inputValue)) {
  //     setCardName(inputValue);
  //     setError(null);
  //   } else {
  //     setCardName("");
  //     setError("Name should only contain letters and spaces");
  //   }
  // };

  // const handleChangeCardName = (e) => {
  //   const inputValue = e.target.value;

  //   if (validateCardName(inputValue)) {
  //     setCardName(inputValue);
  //     setError(null);
  //   } else {
  //     setCardName("");
  //     setError("Name should only contain letters and spaces");
  //   }
  // };
  const handleChangeCardName = (e) => {
    const inputValue = e.target.value;

    if (inputValue === "") {
      setCardName(inputValue);
      setError(null);
    } else if (validateCardName(inputValue)) {
      setCardName(inputValue);
      setError(null);
    } else {
      // Revert to the previous valid value
      e.target.value = cardName;
      setError("Name should only contain letters and spaces");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!cardComplete) {
      toast((t) => (
        <ToastContent
          t={t.id}
          message="Please specify valid card Details"
          color="danger"
          icon={<X size={12} />}
        />
      ));
    } else {
      handleNext(cardName);
    }
  };
  return (
    <div>
      {" "}
      <div className=" mx-50 ">
        {/* <h1 className=" mb-1">Add New Card</h1>
        <p className="">Add card for future billing</p> */}
        <Row
          tag={Form}
          className="gy-1 gx-2 mt-75"
          // onSubmit={handleSubmit(onSubmit)}
        >
          {/* <Col md={12}>
            <Label className="form-label" for="card-name">
              Name On Card
            </Label>
            <Input
              id="card-name"
              placeholder="John Doe"
              value={cardName}
              // invalid={cardName.length === 0}
              onChange={(e) => setCardName(e.target.value)}
            />
          </Col> */}

          <Col md={12}>
            <Label className="form-label" for="card-name">
              Name On Card
            </Label>
            <Input
              maxLength={50}
              id="card-name"
              placeholder="John Doe"
              value={cardName}
              onChange={handleChangeCardName}
            />
          </Col>
          <Col xs={12}>
            <StripeCard
              onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }}
            />
            {error && <FormFeedback>{error.message}</FormFeedback>}
          </Col>

          <Col className="mt-1 text-center text-md-end" xs={12}>
            <Button
              className="mt-1 me-1"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading || !cardName || !cardComplete}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
            <Button
              className="mt-1"
              color="secondary"
              outline
              onClick={() => closeModel(false)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreditCard;
