import React from "react";

const LineSkelaton = ({ style, className }) => {
  return (
    <div
      className={`lineSkelaton ${className ? className : ""}`}
      style={style}
    ></div>
  );
};

export default LineSkelaton;
